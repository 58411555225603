<template>
  <keep-alive>
    <v-container
      fluid
      class="d-flex flex-column h-100 pa-0 min-height-inherit"
      :class="{ 'max-width-1500': profileType != 'Admin' }"
    >
      <div
        class="d-flex flex-row h-100 pa-0 min-height-inherit"
        :class="{ 'max-width-1500': profileType != 'Admin' }"
      >
        <agency-left-section
          class="hidden-sm-and-down"
          v-if="profileType == 'Agency'"
        />
        <volunteer-left-section
          class="hidden-sm-and-down"
          v-else-if="profileType == 'Volunteer'"
        />
        <admin-left-section
          class="hidden-sm-and-down"
          v-else-if="profileType == 'Admin'"
        />
        <notification-feed-section />
        <agency-right-section
          class="hidden-md-and-down"
          v-if="profileType == 'Agency'"
        />
        <volunteer-right-section
          class="hidden-md-and-down"
          v-else-if="profileType == 'Volunteer'"
        />
        <admin-right-section
          class="hidden-md-and-down"
          v-else-if="profileType == 'Admin'"
        />
      </div>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
    </v-container>
  </keep-alive>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import VolunteerLeftSection from "../volunteer/VolunteerLeftSection.vue";
import AgencyLeftSection from "../agency/AgencyLeftSection.vue";
import NotificationFeedSection from "./NotificationFeedSection.vue";
import AgencyRightSection from "../agency/AgencyRightSection.vue";
import VolunteerRightSection from "../volunteer/VolunteerRightSection.vue";
import AdminRightSection from "../admin/AdminRightSection.vue";
import AdminLeftSection from "../admin/home/AdminHomeSideMenu.vue";
export default {
  components: {
    AgencyLeftSection,
    VolunteerLeftSection,
    NotificationFeedSection,
    AgencyRightSection,
    VolunteerRightSection,
    AdminRightSection,
    AdminLeftSection
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      notifications: [],
      errorNotifications: []
    };
  },
  methods: {
    ...mapActions("event", {
      fetchAllEvents: "fetchAllEvents"
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    }
  },
  computed: {
    ...mapGetters({
      profileType: "auth/getType"
    })
  }
};
</script>
<style scoped></style>
