<template>
  <div class="message-section">
    <v-card v-if="user">
      <v-card-text class="d-flex flex-row align-center">
        <v-btn icon @click="backToList"
          ><v-icon>mdi-chevron-left</v-icon></v-btn
        >
        <editable-avatar
          :image="user.thumbnail ? user.thumbnail : user.image"
          :size="40"
          :editable="false"
          class="ml-1"
        />
        <div class="ml-5 mt-1">
          <div class="url-button font-16 app-bold-font" @click="goToProfile">
            {{ user.name ? user.name : user.first_name + " " + user.last_name }}
          </div>
          <div class="font-12">{{ $route.query.type }}</div>
        </div>
        <v-menu offset-y rounded="lg" left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              style="position: absolute; right: 15px; top: 10px"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in dropmenus"
              v-on:change="onSelectedMenu(item)"
              :key="index"
              link
            >
              <v-list-item-title class="mr-3">
                <v-icon v-text="item.icon" class="mr-3" />
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-text>
    </v-card>
    <div
      v-if="statusString == 'connecting'"
      class="d-flex align-center justify-center mt-15"
    >
      <v-progress-circular indeterminate color="primary" />
    </div>
    <Conversation
      v-if="activeConversation"
      :active-conversation="activeConversation"
      :user="user"
      :userChatId="$route.query.type + '-' + $route.query._id"
      :chatId="conversationName"
      style="flex-grow: 1; padding-bottom: 20px;"
    />
    <confirm-delete-dialog
      text="Once you delete your conversation, it cannot be undone."
      :deleteDialog="deleteDialog"
      :onClose="onCloseDialog"
      :onConfirm="onConfirmDelete"
      v-if="deleteDialog"
    />
  </div>
</template>

<script>
import Conversation from "./Conversation";
import { mapActions, mapGetters } from "vuex";
import EditableAvatar from "@/components/EditableAvatar.vue";
import ConfirmDeleteDialog from "@/components/common/ConfirmDeleteDialog.vue";
export default {
  components: { Conversation, EditableAvatar, ConfirmDeleteDialog },
  data() {
    return {
      activeConversation: null,
      isConnected: false,
      user: null,
      dropmenus: [
        { title: "Delete Chat", icon: "mdi-delete" },
        { title: "Report Chat", icon: "mdi-flag" }
      ],
      deleteDialog: false
    };
  },
  methods: {
    ...mapActions({
      initConversationsClient: "chat/initConversationsClient",
      addChat: "chat/addChat",
      deleteChat: "chat/deleteChat",
      getAgency: "agency/getAgency",
      getVolunteer: "volunteer/getVolunteer",
      getAdmin: "auth/getAdmin"
    }),
    createConversation() {
      if (this.$route.query._id && this.$route.query.type) {
        this.getUserInfo();
        this.conversationsClient
          .createConversation({ uniqueName: this.conversationName })
          .then(newConversation => {
            this.joinConversation(newConversation);
          })
          .catch(() => {
            console.log("Join error & get active conversation");
            this.getActiveConversation();
          });
      }
    },
    getActiveConversation() {
      this.conversationsClient
        .getConversationByUniqueName(this.conversationName)
        .then(res => {
          this.activeConversation = res;
          this.checkUsers();
          this.addChat(this.$route.query.type + "-" + this.$route.query._id);
        })
        .catch(error => {
          console.log("get active conversation failed", error.message);
          if (error.message == "Not Found") {
            console.log("force reload");
            this.$router.go();
            // location.reload(); //other option to reload browser
            // this.deleteChat(this.conversationName)
            //   .then(() => {
            //     this.createConversation();
            //   })
            //   .catch((error) => {
            //     console.log(error.response.data.message);
            //   });
          } else {
            this.$router.go();
          }
        });
    },
    joinConversation(newConversation) {
      newConversation
        .join()
        .then(joinedConversation => {
          joinedConversation
            .add(this.profileType + "-" + this.profile._id)
            .catch(err => console.log("join error1: ", err));
          joinedConversation
            .add(this.$route.query.type + "-" + this.$route.query._id)
            .catch(err => console.log("join error2: ", err));
          this.activeConversation = joinedConversation;
          this.addChat(this.$route.query.type + "-" + this.$route.query._id);
        })
        .catch(() => {
          console.log("join error & quit");
        });
    },
    checkUsers() {
      var addMe = true;
      var addOther = true;
      var array = Array.from(this.activeConversation.participants);
      if (array.length > 0) {
        if (
          array[0][1].state.identity ==
          this.profileType + "-" + this.profile._id
        ) {
          addMe = false;
        } else if (
          array[0][1].state.identity ==
          this.$route.query.type + "-" + this.$route.query._id
        ) {
          addOther = false;
        }
      }
      if (array.length == 2) {
        if (
          addMe &&
          array[1][1].state.identity ==
            this.profileType + "-" + this.profile._id
        ) {
          addMe = false;
        } else if (
          addOther &&
          array[1][1].state.identity ==
            this.$route.query.type + "-" + this.$route.query._id
        ) {
          addOther = false;
        }
      }
      if (addMe) {
        this.activeConversation
          .add(this.profileType + "-" + this.profile._id)
          .catch(err => console.log("add me error: ", err.message));
      }
      if (addOther) {
        this.activeConversation
          .add(this.$route.query.type + "-" + this.$route.query._id)
          .catch(err => {
            console.log("add other error: ", err.message);
            if (err.message == "Bad Request") {
              // this.onDeleteChat();
            }
          });
      }
    },
    getUserInfo() {
      if (this.$route.query.type == "Agency") {
        this.getAgency(this.$route.query._id)
          .then(res => {
            this.user = res;
          })
          .catch(error => {
            if (error.response.data.message == "Agency not found") {
              this.user = { name: "Deleted User" };
            } else {
              console.log(error.response.data.message);
            }
          });
      } else if (this.$route.query.type == "Volunteer") {
        this.getVolunteer({ _id: this.$route.query._id })
          .then(res => {
            this.user = res;
          })
          .catch(error => {
            if (error.response.data.message == "User not found") {
              this.user = { name: "Deleted User" };
            } else {
              console.log(error.response.data.message);
            }
          });
      } else if (this.$route.query.type == "Admin") {
        this.getAdmin({ _id: this.$route.query._id })
          .then(res => {
            this.user = res;
          })
          .catch(error => {
            if (error.response.data.message == "Admin not found") {
              this.user = { name: "Deleted User" };
            } else {
              console.log(error.response.data.message);
            }
          });
      }
    },
    onSelectedMenu(item) {
      if (item.title == "Delete Chat") {
        this.deleteDialog = true;
      }
    },
    onConfirmDelete() {
      this.onDeleteChat();
      this.deleteDialog = false;
    },
    onCloseDialog() {
      this.deleteDialog = false;
    },
    onDeleteChat() {
      this.deleteChat(this.conversationName)
        .then(() => {
          this.activeConversation = null;
          this.$router.push({ name: "mobile-chat" });
        })
        .catch(error => {
          console.log(error.response.data.message);
        });
    },
    goToProfile() {
      if (this.$route.query.type == "Agency") {
        this.$router.push({
          name: "agency-profile-details",
          params: { id: this.$route.query._id }
        });
      } else if (this.$route.query.type == "Volunteer") {
        this.$router.push({
          name: "volunteer-profile-details",
          params: { id: this.$route.query._id }
        });
      }
    },
    backToList() {
      if (window.history.length > 2) {
        this.$router.back();
      } else {
        this.$router.push({ name: "mobile-chat" });
      }
    }
  },
  computed: {
    ...mapGetters({
      profileType: "auth/getType",
      profile: "auth/getProfile",
      conversationsClient: "chat/conversationsClient",
      statusString: "chat/getStatus"
    }),
    conversationName() {
      var name1 = this.profileType + "-" + this.profile._id;
      var name2 = this.$route.query.type + "-" + this.$route.query._id;
      if (name1 < name2) {
        return name1 + ":" + name2;
      } else if (name1 > name2) {
        return name2 + ":" + name1;
      }
      return name1;
    }
  },
  watch: {
    statusString(newValue) {
      if (newValue == "connected" && !this.activeConversation) {
        this.createConversation();
      }
    },
    "$route.query._id": function(newValue) {
      if (newValue && this.statusString == "connected") {
        this.activeConversation = null;
        this.createConversation();
      } else if (this.statusString == "disconnected") {
        this.initConversationsClient();
      } else if (this.statusString == "denied") {
        this.$router.go();
      } else {
        console.log(this.statusString);
        this.$router.go();
      }
    }
  },
  mounted() {
    if (
      this.$route.query._id &&
      this.statusString == "connected" &&
      !this.activeConversation
    ) {
      this.createConversation();
    } else if (this.$route.query._id && this.statusString == "disconnected") {
      this.initConversationsClient();
    } else {
      console.log(this.statusString);
    }
  }
};
</script>

<style scoped>
.message-section {
  width: 100%;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
  padding: 0px;
}
</style>
